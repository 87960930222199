import React from 'react'

import { PseudoBox, Flex, Box } from '@chakra-ui/core'

const Card = ({ head, body, ...props }) => {
    return (
        <PseudoBox
            data-class="card"
            as={Flex}
            position="relative"
            direction="column"
            rounded="md"
            minW="0"
            boxShadow="lg"
            {...props}
        >
            <Box borderBottom="1px solid rgba(0,0,0,.05)" pt="5" pb="12" px="6">
                {head}
            </Box>
            <Box p="12">{body}</Box>
        </PseudoBox>
    )
}

export default Card
