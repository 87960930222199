import React from 'react'
import { Link, Flex, PseudoBox, Icon, Text } from '@chakra-ui/core'
import Container from 'src/components/common/Container'
import { FiLogIn } from 'react-icons/fi'

const hoverStyles = {
    _hover: {
        textDecoration: 'none',
        color: 'gray.100',
    },
}

const Navbar = ({ ...props }) => {
    return (
        <PseudoBox
            as={Flex}
            position="absolute"
            left="0"
            top="0"
            width="100%"
            zIndex="1"
            py="4"
            color="white"
            {...props}
        >
            <Flex as={Container} px="6" rounded="lg" align="center">
                <PseudoBox as={Link} href="#" fontWeight="600" {...hoverStyles}>
                    {`\<\/\>`} rixcy
                </PseudoBox>
                <Flex ml="auto" direction="row">
                    <PseudoBox
                        as={Link}
                        href="#"
                        mr="2"
                        px="2"
                        py="4"
                        fontWeight="normal"
                        _last={{ mr: 0 }}
                        display="flex"
                        alignItems="center"
                        {...hoverStyles}
                    >
                        <Icon as={FiLogIn} />
                        <Text pl="1">Login</Text>
                    </PseudoBox>
                </Flex>
            </Flex>
        </PseudoBox>
    )
}

export default Navbar
