import React from 'react'
import { Box } from '@chakra-ui/core'

const Container = ({ children, ...props }) => (
    <Box
        data-class="container"
        maxWidth={['100%', '540px', '720px', '960px', '1140px']}
        marginLeft="auto"
        marginRight="auto"
        paddingLeft="4"
        paddingRight="4"
        w="100%"
        align="center"
        justify="space-between"
        {...props}
    >
        {children}
    </Box>
)

export default Container
