import React from 'react'

import { Button, Text, Flex } from '@chakra-ui/core'

const ButtonStyles = {
    variant: 'outline',
    color: 'black',
    mr: '2',
    _last: { mx: 0 },
}

import { FaGithub, FaGoogle } from 'react-icons/fa'

import usePrimaryColor from 'src/hooks/usePrimaryColor'

const LoginHead = () => {
    const [primaryColor] = usePrimaryColor()
    return (
        <>
            <Text
                textAlign="center"
                color="gray.600"
                fontSize="sm"
                mt="2"
                mb="4"
            >
                Sign in with
            </Text>
            <Flex justify="center">
                <Button
                    leftIcon={FaGithub}
                    variantColor={primaryColor}
                    {...ButtonStyles}
                >
                    Github
                </Button>
                <Button
                    leftIcon={FaGoogle}
                    variantColor={primaryColor}
                    {...ButtonStyles}
                >
                    Google
                </Button>
            </Flex>
        </>
    )
}

export default LoginHead
