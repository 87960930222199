import React from 'react'

import { PseudoBox, Flex, Link, Text } from '@chakra-ui/core'

import Container from 'src/components/common/Container'

const Footer = ({ children, ...props }) => {
    return (
        <PseudoBox as="footer" py="12" {...props}>
            <Flex
                as={Container}
                align="center"
                justify={['center', , , 'space-between']}
            >
                <Text color="white">
                    © {new Date().getFullYear()}
                    {` `}
                    <Link
                        color="gray.300"
                        _hover={{ color: 'gray.400' }}
                        href="https://rickbooth.me"
                        isExternal
                    >
                        Rick Booth
                    </Link>
                </Text>
            </Flex>
        </PseudoBox>
    )
}

export default Footer
