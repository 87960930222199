import React, { useState, useContext } from 'react'

import {
    Button,
    Text,
    Flex,
    Input,
    ControlBox,
    VisuallyHidden,
    Icon,
    Box,
    InputGroup,
    InputLeftElement,
    InputRightElement,
} from '@chakra-ui/core'

import { FaEnvelope, FaUnlock } from 'react-icons/fa'

import usePrimaryColor from 'src/hooks/usePrimaryColor'

const LoginBody = () => {
    const [primaryColor] = usePrimaryColor()
    const [show, setShow] = useState(false)
    const InputStyles = {
        b: 'none',
        bg: 'white',
        mb: 6,
        fontSize: 'sm',
        boxShadow: 'md',
        variant: 'outline',
    }
    return (
        <>
            <Text textAlign="center" color="gray.600" mb="6" fontSize="xs">
                Or sign in with credentials
            </Text>
            <InputGroup size="lg">
                <InputLeftElement
                    children={<Box as={FaEnvelope} color="gray.300" />}
                />
                <Input type="email" placeholder="Email" {...InputStyles} />
            </InputGroup>
            <InputGroup size="lg">
                <InputLeftElement
                    children={<Box as={FaUnlock} color="gray.300" />}
                />
                <Input
                    type={show ? 'text' : 'password'}
                    placeholder={show ? 'password' : '********'}
                    {...InputStyles}
                />
                <InputRightElement width="4.5rem">
                    <Button
                        backgroundColor="gray.100"
                        _focus={{ outline: 'none' }}
                        _hover={{ backgroundColor: 'gray.200' }}
                        variant="solid"
                        h="1.75rem"
                        size="sm"
                        onClick={() => setShow(!show)}
                    >
                        {show ? 'Hide' : 'Show'}
                    </Button>
                </InputRightElement>
            </InputGroup>
            <Flex as="label" align="center">
                <VisuallyHidden as="input" type="checkbox" />

                <ControlBox
                    borderWidth="1px"
                    size="24px"
                    rounded="sm"
                    _checked={{
                        bg: `${primaryColor}.500`,
                        color: 'white',
                        borderColor: `${primaryColor}.500`,
                    }}
                    _focus={{
                        borderColor: `${primaryColor}.600`,
                        boxShadow: 'outline',
                    }}
                >
                    <Icon name="check" size="16px" />
                </ControlBox>

                <Box as="span" ml={3} fontSize="sm" userSelect="none">
                    Remember me
                </Box>
            </Flex>
            <Button
                mt="4"
                variant="solid"
                color="white"
                mx="auto"
                d="block"
                bg={`${primaryColor}.400`}
                _hover={{ bg: `${primaryColor}.500` }}
                _active={{ bg: `${primaryColor}.600` }}
            >
                Login
            </Button>
        </>
    )
}

export default LoginBody
