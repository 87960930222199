import React from 'react'

import { PseudoBox, Flex } from '@chakra-ui/core'

const Row = ({ children, ...props }) => {
    return (
        <PseudoBox
            data-class="row"
            as={Flex}
            justifyContent="center"
            wrap="wrap"
            mx="-15px"
            {...props}
        >
            {children}
        </PseudoBox>
    )
}

export default Row
