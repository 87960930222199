import React from 'react'
import { Box, Text, useTheme } from '@chakra-ui/core'

import Container from 'src/components/common/Container'
import Skew from 'src/components/common/Skew'

import usePrimaryColor from 'src/hooks/usePrimaryColor'

const Header = ({ title, text, ...props }) => {
    const [primaryColor] = usePrimaryColor()
    const theme = useTheme()
    return (
        <Box
            as="header"
            py="32"
            background={`linear-gradient(85deg,${theme.colors[primaryColor][400]},${theme.colors[primaryColor][500]})`}
            color="white"
            position="relative"
            {...props}
        >
            <Container textAlign="center" flexDirection="column" mb="24">
                <Text as="h1" fontSize="2xl" mb="3">
                    {title}
                </Text>
                <Text>{text}</Text>
            </Container>
            <Skew fill={`${primaryColor}.800`} />
        </Box>
    )
}

export default Header
