import { theme } from '@chakra-ui/core'

const customTheme = {
    ...theme,
    colors: {
        ...theme.colors,
    },
    fonts: {
        body: `Montserrat, sans-serif`,
        heading: `Montserrat, sans-serif`,
    },
    radii: {
        ...theme.radii,
        md: '0.375rem',
    },
    breakpoints: ['576px', '768px', '992px', '1200px'],
}

if (process.env.NODE_ENV != 'production') {
    console.warn('Theme: ', customTheme)
}

export default customTheme
