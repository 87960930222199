import React from 'react'

import { PseudoBox, Button, Text, Flex } from '@chakra-ui/core'

import Container from 'src/components/common/Container'
import Row from 'src/components/common/Row'
import Card from 'src/components/common/Card'

import LoginHead from './head'
import LoginBody from './body'

const Login = ({ ...props }) => {
    return (
        <PseudoBox as={Container} pb="6" mt="-8rem" {...props}>
            <Row>
                <Card
                    bg="gray.50"
                    head={<LoginHead />}
                    body={<LoginBody />}
                    w={['100%', '100%', '60%', '45%', '45%']}
                    mx="4"
                    position="relative"
                />
            </Row>
        </PseudoBox>
    )
}

export default Login
