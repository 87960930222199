import React from 'react'
import { Helmet } from 'react-helmet'
import {
    Box,
    ThemeProvider,
    CSSReset,
    ColorModeProvider,
} from '@chakra-ui/core'
import customTheme from '../../theme'

import Navbar from 'src/components/Navbar'
import Header from 'src/components/Header'
import Login from 'src/components/Login'
import Footer from 'src/components/Footer'

import usePrimaryColor from 'src/hooks/usePrimaryColor'

function Index() {
    const [primaryColor] = usePrimaryColor()
    return (
        <ThemeProvider theme={customTheme}>
            <Helmet>
                <title>Gatsby + Node.js (TypeScript) API</title>
            </Helmet>
            <CSSReset />
            <ColorModeProvider>
                <Navbar />
                <Box bg={`${primaryColor}.800`} minH="100vh">
                    <Header
                        title={`Welcome!`}
                        text={`Please log in using the form below`}
                    />
                    <Login />
                    <Footer />
                </Box>
            </ColorModeProvider>
        </ThemeProvider>
    )
}

export default Index
