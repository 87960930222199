import React from 'react'
import { PseudoBox, Box } from '@chakra-ui/core'

const Skew = ({ fill, ...props }) => {
    return (
        <PseudoBox
            data-class="skew"
            left="0"
            bottom="0"
            w="100%"
            position="absolute"
            {...props}
        >
            <Box
                as="svg"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
                b="0"
            >
                <Box as="polygon" fill={fill} points="0 0 2560 100 0 100"></Box>
            </Box>
        </PseudoBox>
    )
}

export default Skew
